<template>
<!--    <div style="position: relative">-->
<!--        <world-map height="500px" :series="series" :loading="map_loading"></world-map>-->
<!--        <metric-card style="position: absolute;top: 60px;left:20px" v-loading="table_loading">-->
<!--            <template v-for="m in metric_list">-->
<!--                <p class="title" v-if="m.value===metric">{{ m.label }}（{{ date }}）</p>-->
<!--            </template>-->
<!--            <p class="value">{{ formatInt(main_value[metric]) }}</p>-->
<!--            <p class="title">每日数据</p>-->
<!--            <div style="height: 44px" id="bar"></div>-->
<!--            <p class="title">用户来源占比</p>-->
<!--            <div style="height: 154px" id="pie"></div>-->
<!--            <p style="font-size: 22px;line-height: 28px;text-align: center;margin: 0">-->
<!--                <span style="margin-right: 25px">{{ main_left }}%</span><span-->
<!--                style="margin-left: 55px">{{ main_right }}%</span>-->
<!--            </p>-->
<!--        </metric-card>-->
<!--    </div>-->
    <div style="padding: 0 30px;margin-top: 20px">
        <downloadable-table :data="data" :loading="table_loading" title="历史数据" show-summary
                            :summary-method="summaryMethod" :height="height">
            <template #header>
                <div style="margin-bottom: 5px;display: inline-flex">
                    <date-range-picker v-model="form" :disabled="table_loading"></date-range-picker>
                    <el-select v-model="form.country"  filterable :disabled="table_loading"
                               style="margin-left: 5px" clearable placeholder="全部国家">
                        <el-option v-for="c in $root.country_list" :key="c.code" :value="c.code"
                                   :label="`${c.en_name}(${c.code})`"></el-option>
                    </el-select>
                    <el-button  type="primary" :loading="table_loading" @click="getTableData"
                               style="margin-left: 5px">查询
                    </el-button>
                </div>
            </template>
            <el-table-column width="120" prop="date" label="日期" fixed="left"></el-table-column>
            <el-table-column width="100" prop="bq_installs" label="首次打开"></el-table-column>
            <el-table-column width="100" prop="ua_installs" label="非自然量"></el-table-column>
            <el-table-column width="100" prop="bq_dau" label="日活"></el-table-column>
            <el-table-column width="120" prop="bq_duration_seconds" label="人均时长"></el-table-column>
            <el-table-column width="100" prop="bq_sessions" label="人均会话">
                <template #default="scope">
                    {{ scope.row.bq_dau ? (scope.row.bq_sessions / scope.row.bq_dau).toFixed(2) : null }}
                </template>
            </el-table-column>
            <el-table-column width="100" prop="bq_retention_1" label="次留">
                <template #default="scope">
                    {{ scope.row.bq_retention_1 ? scope.row.bq_retention_1 + '%' : '' }}
                </template>
            </el-table-column>
            <el-table-column width="100" prop="bq_retention_7" label="7留">
                <template #default="scope">
                    {{ scope.row.bq_retention_7 ? scope.row.bq_retention_7 + '%' : '' }}
                </template>
            </el-table-column>
            <el-table-column width="100" prop="bq_retention_30" label="30留">
                <template #default="scope">
                    {{ scope.row.bq_retention_30 ? scope.row.bq_retention_30 + '%' : '' }}
                </template>
            </el-table-column>
            <el-table-column width="100" label="总收入" prop="revenue">
                <template #default="scope">
                    {{
                        scope.row.iaa_revenue + scope.row.iap_proceed ? (scope.row.iaa_revenue + scope.row.iap_proceed).toFixed(2) : null
                    }}
                </template>
            </el-table-column>
            <el-table-column width="100" label="总花费" prop="spend">
                <template #default="scope">
                    {{ scope.row.ua_spend }}
                </template>
            </el-table-column>
            <el-table-column width="100" label="利润" prop="profit">
                <template #default="scope">
                    {{
                        scope.row.iaa_revenue + scope.row.iap_proceed - scope.row.ua_spend ? (scope.row.iaa_revenue + scope.row.iap_proceed - scope.row.ua_spend).toFixed(2) : null
                    }}
                </template>
            </el-table-column>
            <el-table-column width="100" label="平均单价" prop="cpa">
                <template #default="scope">
                    {{ (scope.row.ua_spend / scope.row.ua_installs).toFixed(2) }}
                </template>
            </el-table-column>
            <el-table-column width="100" label="单位成本" prop="cpi">
                <template #default="scope">
                    {{ (scope.row.ua_spend / scope.row.bq_installs).toFixed(2) }}
                </template>
            </el-table-column>
            <el-table-column width="100" label="ARPDAU" prop="arpdau">
                <template #default="scope">
                    {{
                        (scope.row.iaa_revenue + scope.row.iap_proceed) / scope.row.bq_dau ? ((scope.row.iaa_revenue + scope.row.iap_proceed) / scope.row.bq_dau).toFixed(4) : null
                    }}
                </template>
            </el-table-column>
            <el-table-column width="120" label="广告ARPDAU" prop="arpdau">
                <template #default="scope">
                    {{
                        scope.row.iaa_revenue / scope.row.bq_dau ? (scope.row.iaa_revenue / scope.row.bq_dau).toFixed(4) : null
                    }}
                </template>
            </el-table-column>
            <el-table-column width="120" label="内购ARPDAU" prop="arpdau">
                <template #default="scope">
                    {{
                        scope.row.iap_proceed / scope.row.bq_dau ? (scope.row.iap_proceed / scope.row.bq_dau).toFixed(4) : null
                    }}
                </template>
            </el-table-column>
            <el-table-column label="内购收入数据">
                <el-table-column width="100" label="销售额" prop="iap_revenue"></el-table-column>
                <el-table-column width="100" label="实际收入" prop="iap_proceed"></el-table-column>
                <el-table-column width="100" label="单次购买" prop="iap_inapp"></el-table-column>
                <el-table-column width="100" label="订阅" prop="iap_subscription"></el-table-column>
            </el-table-column>
            <el-table-column label="广告收入数据" v-if="cash_channel.length">
                <el-table-column width="100" label="广告总收入" prop="iaa_revenue"></el-table-column>
                <el-table-column v-for="c in cash_channel" :key="c" :label="c + '收入'" width="150"
                                 :prop="'' + c + '_revenue'" show-overflow-tooltip></el-table-column>
            </el-table-column>
            <el-table-column label="投放花费数据" v-if="ad_channel.length">
                <el-table-column width="100" label="总花费" prop="ua_spend">
                    <template #default="scope">
                        {{ scope.row.ua_spend }}
                    </template>
                </el-table-column>
                <template v-for="c in ad_channel" :key="c">
                    <el-table-column width="130" :label="c + '花费'" :prop="c + '_spend'"></el-table-column>
                    <el-table-column width="130" :label="c + '安装'" :prop="c + '_install'"></el-table-column>
                    <el-table-column width="130" :label="c + '单价'" :prop="c + '_cpi'">
                        <template #default="scope">
                            {{
                                scope.row[c + '_install'] ? (scope.row[c + '_spend'] / scope.row[c + '_install']).toFixed(2) : null
                            }}
                        </template>
                    </el-table-column>
                </template>
            </el-table-column>
        </downloadable-table>
    </div>
</template>

<script>
import WorldMap from "../../base/WorldMap";
import axios from 'ts-axios-new';
import {timestampToDate} from "../../libs/utils";
import MetricCard from "./MetricCard";
import DownloadableTable from "../../base/DownloadableTable";
import {formatDuration, formatInt, generateDateRange} from "../../libs/utils";
import DateRangePicker from "../../base/DateRangePicker";

export default {
    name: "PMDashboard",
    components: {DateRangePicker, DownloadableTable, MetricCard, WorldMap},
    data() {
        const now = Date.now();
        return {
            series: [], main_value: {}, height: this.$root.height,
            date: timestampToDate(now - this.$root.t * 24 * 3600 * 1000),
            data: [], metric: 'bq_installs', map_loading: false, table_loading: false, main_left: 50, main_right: 50,
            metric_list: [
                {label: '首次打开', value: 'bq_installs'},
                {label: '日活', value: 'bq_dau'},
                {label: '非自然量', value: 'ua_installs'},
                {label: '人均时长', value: 'bq_duration_seconds'},
                {label: '次日留存', value: 'bq_retention_1'},
                {label: '7日留存', value: 'bq_retention_7'},
            ],
            form: {
                start: timestampToDate(now - 32 * 24 * 3600 * 1000) > '2021-09-01' ? timestampToDate(now - 32 * 24 * 3600 * 1000) : '2021-09-01',
                end: timestampToDate(now - this.$root.t * 24 * 3600 * 1000),
                country: '',
                product_id: this.$route.params.productId,
            },
            ad_channel: [], cash_channel: [],
            ad_admin: [], cash_admin: [],
        }
    },
    methods: {
        convertData(data) {
            const res = [];
            for (let i = 0; i < data.length; i++) {
                const geo = country[data[i].country];
                if (geo) {
                    res.push({
                        name: geo.cn_name,
                        value: geo.coord.concat(data[i].value),
                    })
                }
            }
            return res;
        },
        refreshMap() {
            if (this.data.length) {
                this.main_value = this.data[0];
                this.main_left = this.data[0].ua_installs > this.data[0].bq_installs ? 100 : parseInt(this.data[0].ua_installs * 100 / this.data[0].bq_installs);
                this.main_right = this.data[0].ua_installs > this.data[0].bq_installs ? 0 : 100 - parseInt(this.data[0].ua_installs * 100 / this.data[0].bq_installs);
                const x = generateDateRange(this.form.start, this.form.end);
                const y = [];
                this.data.forEach(d => {
                    y.unshift([d.date, d[this.metric]]);
                });
                echarts.init(document.getElementById('bar')).setOption({
                    xAxis: {
                        type: 'category',
                        data: x,
                        show: false,
                    },
                    yAxis: {
                        type: 'value',
                        show: false,
                    },
                    series: [{
                        type: 'bar',
                        data: y,
                        itemStyle: {
                            color: '#3475E0'
                        },
                        emphasis: {
                            focus: 'self',
                        }
                    }],
                    tooltip: {
                        formatter: function (value) {
                            return `<span class="title">${value.value[0]}</span><br/><span style="font-size: 18px;line-height: 24px">${value.value[1]}</span>`
                        }
                    },
                    grid: {
                        left: 0,
                        right: 0,
                    },
                });
                echarts.init(document.getElementById('pie')).setOption({
                    tooltip: {},
                    legend: {
                        top: 'bottom',
                        itemGap: 50
                    },
                    series: [{
                        name: '用户来源',
                        type: 'pie',
                        radius: ['40%', '60%'],
                        center: ['50%', '40%'],
                        data: [{
                            name: '非自然量', value: this.data[0].ua_installs
                        }, {
                            name: '自然量', value: this.data[0].bq_installs - this.data[0].ua_installs
                        }],
                        label: {
                            show: false,
                        },
                        grid: {
                            top: 0,
                            bottom: 0,
                        },
                    }]
                });
            }
        },
        init() {
            axios.all([
                this.getMapData(),
                this.getTableData(),
            ]).then(_ => {
                this.refreshMap();
            })
        },
        getTableData() {
            this.table_loading = true;
            this.ad_channel = [];
            this.cash_channel = [];
            return axios.get(`/api/v1/pm/${this.$route.params.productId}/report`, {
                params: this.form
            }).then(res => {
                res.data.data.metricList.forEach(d => {
                    d.bq_duration_seconds = d.bq_duration_seconds ? formatDuration(d.bq_duration_seconds * 1000) : null;
                    d.session = d.bq_dau ? (d.session / d.bq_dau).toFixed(2) : null;
                });
                this.data = res.data.data.metricList;
                this.ad_channel = res.data.data.ad_channel;
                this.cash_channel = res.data.data.cash_channel;
                this.table_loading = false;
            });
        },
        getMapData() {
            this.map_loading = true;
            return axios.get(`/api/v1/pm/${this.$route.params.productId}/stat/geo`, {
                params: {
                    start: this.date,
                    end: this.date,
                    metric: this.metric,
                }
            }).then(res => {
                if (res.data.data.metricList.length) {
                    const length = (parseInt(res.data.data.metricList[0].value) + '').length;
                    this.series = [{
                        name: this.metric,
                        type: 'scatter',
                        // data: this.convertData(res.data.data.metricList),
                        encode: {
                            value: 2,
                        },
                        label: {
                            formatter: function (value) {
                                return `${value.name}: ${value.value[2]}`;
                            },
                            position: 'right'
                        },
                        symbolSize: function (val) {
                            return val[2] * 400 / 10 ** length;
                        },
                        coordinateSystem: 'bmap',
                        itemStyle: {
                            color: '#769EE0'
                        },
                        emphasis: {
                            label: {
                                show: true
                            }
                        },
                    }];
                }
                this.map_loading = false;
            })
        },
        formatInt(num) {
            return formatInt(num);
        },
        summaryMethod({columns, data}) {
            const summary = [];
            columns.forEach((c, i) => {
                if (['bq_dau', 'iaa_revenue'].includes(c.property) || c.property.includes('_revenue') || c.property.includes('_spend') || c.property.includes('iap') || c.property.includes('install')) {
                    summary[i] = 0;
                    let counter = 0;
                    data.forEach(d => {
                        if (d[c.property]) {
                            summary[i] += d[c.property];
                            counter += 1;
                        }
                    });
                    if (c.property === 'bq_dau') {
                        summary[i] = parseInt(summary[i] / counter + '');
                    } else if (c.property.includes('install')) {
                        summary[i] = parseInt(summary[i]);
                    } else {
                        summary[i] = summary[i].toFixed(2);
                    }
                } else if (c.property === 'revenue') {
                    summary[i] = 0;
                    data.forEach(d => {
                        summary[i] += d.iaa_revenue + d.iap_proceed;
                    });
                    summary[i] = summary[i].toFixed(2);
                } else if (c.property === 'spend') {
                    summary[i] = 0;
                    data.forEach(d => {
                        summary[i] += d.ua_spend;
                    });
                    summary[i] = summary[i].toFixed(2);
                } else if (c.property === 'profit') {
                    summary[i] = 0;
                    data.forEach(d => {
                        summary[i] += d.iaa_revenue + d.iap_proceed - d.ua_spend;
                    });
                    summary[i] = summary[i].toFixed(2);
                }
            });
            return summary;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        "$route.params.productId"() {
            if (this.$route.params.productId) {
                this.init();
            }
        }
    },
}
</script>

<style scoped>
</style>