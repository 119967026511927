<template>
    <div style="position: relative;">
        <el-container style="position: relative">
            <div class="global-cover color-modest" v-if="loading" style="font-size: 18px">
                <div style="position: absolute;color: var(--el-text-color-primary);"
                     :style="{top: height / 2 + 'px', left: width / 2 - 50 + 'px'}">
                    <div style="font-size: 12px;">
                        <img src="https://bi-cdn.plutodesk.com/plutodesk.png" class="fa-spin"
                             style="width: 30px;vertical-align: middle">
                        正在努力加载。。。
                    </div>
                </div>
            </div>
            <el-header height="64px">
                <div style="display: flex">
                    <div class="brand span">
                        <img src="https://bi.plutodesk.com/static/plutodesk.png" @click="$router.push({name: 'Index'})"
                             style="margin: 16px 0;height: 32px;cursor: pointer;">
                    </div>
                    <div class="title">
                        {{ title }}
                        <el-divider direction="vertical" style="font-size: 32px"></el-divider>
                    </div>
                    <div class="product" v-if="$route.params.productId">
                        <el-image :src="product.avatar" @click="openInNewTab(product.url)" lazy
                                  style="width: 32px;cursor: pointer;margin: 16px 0;border-radius: 5px"></el-image>
                        <el-popover placement="bottom" trigger="click" width="350px" @hide="query=''" ref="popover">
                            <template #default>
                                <el-input size="small" prefix-icon="Search" placeholder="产品搜索"
                                          v-model="query" ref="search"></el-input>
                                <el-scrollbar max-height="300px">
                                    <div v-for="p in queryProduct" :key="p.id" @click="changeProduct(p)">
                                        <div class="product-nav"
                                             :style="{backgroundColor: p.id===product.id?'var(--el-border-color-lighter)': ''}">
                                            <img :src="p.avatar" style="width: 40px;border-radius: 5px">
                                            <div style="margin-left: 15px;white-space: nowrap;width: 280px">
                                                <el-row style="line-height: 24px;font-size: 16px;">
                                                    {{ p.nickname || p.name }}
                                                    ({{ p.platform === 'ios' ? 'iOS' : 'Android' }})
                                                </el-row>
                                                <el-row style="line-height: 16px;font-size: 12px;">
                                                    {{ p.app_id }}
                                                </el-row>
                                            </div>
                                        </div>
                                    </div>
                                </el-scrollbar>
                            </template>
                            <template #reference>
                            <span style="margin-left: 10px;color: var(--el-text-color-secondary);cursor: pointer">
                                {{ product.nickname || product.name }}
                                ({{ product.platform === 'ios' ? 'iOS' : 'Android' }})
                                <el-icon size="14px">
                                    <CaretBottom/>
                                </el-icon>
                            </span>
                            </template>
                        </el-popover>
                    </div>
                </div>
                <div>
                    <el-switch style="margin: 16px" v-model="dark" active-color="#444444" @change="changeDark"
                               active-icon="Moon" inactive-icon="Sunny" inline-prompt></el-switch>
                </div>
            </el-header>
            <el-container :style="{height: height + 'px'}">
                <router-view></router-view>
            </el-container>
        </el-container>
<!--        <div id="cat" @click="openChatGPT"></div>-->
<!--        <div id="chat-gpt" ref="chat-gpt">-->
<!--            <p style="display: flex; justify-content: space-between; padding-left: 10px; padding-right: 10px; align-items: center;">-->
<!--                <span style="flex-shrink: 1; text-align: center; width: 90%">ChatGPT4</span>-->
<!--                <el-icon style="cursor: pointer;" @click="closeChatGPT">-->
<!--                    <Close/>-->
<!--                </el-icon>-->
<!--            </p>-->
<!--            <div style="height: calc(100% - 54px)">-->
<!--                <iframe src="https://chatgpt.plutodesk.com" width="100%" height="100%" frameborder="0"></iframe>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</template>

<script>
import axios from 'ts-axios-new';
import {update, openInNewTab, downloadTable} from './libs/utils'
import {h} from 'vue'
import {useDark, useToggle} from '@vueuse/core'
import lottie from 'lottie-web';

export default {
    name: 'App',
    data() {
        return {
            title: '统计分析平台',
            loading: false,
            height: document.documentElement.clientHeight - 66,
            width: document.documentElement.clientWidth,
            user: {
                user_id: '',
                avatar: '',
                name: '',
            },
            country_list: [],
            product_list: [],
            user_list: [],
            product: {
                id: '', avatar: '', name: '', nickname: '', platform: '', url: '',
            },
            query: '',
            popover: false,
            t: 1,  // 根据数仓及时性调整,
            dark: window.matchMedia('(prefers-color-scheme: dark)').matches,
        }
    },
    methods: {
        openInNewTab(url) {
            return openInNewTab(url);
        },
        handleScroll() {
            this.height = document.documentElement.clientHeight - 66;
            this.width = document.documentElement.clientWidth;
        },
        logout() {
            axios.get('/api/v1/uac/logout').then(_ => {
                this.user.user_id = this.user.avatar = this.user.name = '';
            })
        },
        checkUpdate() {
            const timer = setTimeout(() => {
                axios.get('/version.json?random=' + parseInt(Math.random() * 10000)).then(res => {
                    const timeStamp = parseInt(res.data);
                    if (timeStamp > this.timeStamp) {
                        this.$notify.info({
                            title: '发现更新',
                            dangerouslyUseHTMLString: true,
                            message: h('div', [
                                h('p', {style: 'margin-bottom: 12px'}, '是否现在刷新页面，获取最新更新？'),
                                h('button', {
                                    type: 'button',
                                    class: 'el-button el-button--mini',
                                    onClick: this.cancelClick,
                                }, '两小时后提醒'),
                                h('button', {
                                    type: 'button',
                                    class: 'el-button el-button--mini el-button--primary',
                                    onClick: this.confirmClick,
                                }, '立即刷新')
                            ]),
                            duration: 0,
                            onClose: () => {
                                this.minute = 120;
                                this.checkUpdate()
                            }
                        })
                    } else {
                        this.minute = 5;
                        this.checkUpdate()
                    }
                });
            }, this.minute * 60000);
        },
        confirmClick() {
            window.location.reload()
        },
        cancelClick() {
            this.$notify.closeAll()
        },
        init() {
            if (!import.meta.env.VITE_DISABLED_AUTO_UPDATE) {
                axios.get('/version.json?random=' + parseInt(Math.random() * 10000)).then(res => {
                    this.timeStamp = parseInt(res.data);
                    localStorage.setItem('timeStamp', this.timeStamp);
                    this.checkUpdate();
                });
            }
        },
        getProduct() {
            this.product_list.forEach(p => {
                if (p.id === this.$route.params.productId) {
                    update(this.product, p);
                }
            });
        },
        changeProduct(p) {
            this.$refs.popover.hide();
            if (p.id !== this.$route.params.productId) {
                this.$router.push({params: {productId: p.id}, query: this.$route.query})
            }
        },
        $downloadTable(el, name, callback) {
            this.loading = true;
            downloadTable(el, name, _ => {
                if (callback)
                    callback();
                this.loading = false;
            })
        },
        $getElasticDomain() {
            return import.meta.env.VITE_ELASTIC_PROXY ? import.meta.env.VITE_ELASTIC_PROXY : `https://${this.$route.query.domain}.plutodesk.com`
        },
        changeDark() {
            useToggle(useDark())(this.dark);
        },
        openChatGPT() {
            this.$refs["chat-gpt"].style.display = 'block';
        },
        closeChatGPT() {
            this.$refs["chat-gpt"].style.display = 'none';
        },
    },
    mounted() {
        window.onresize = _ => {
            this.handleScroll();
        };
        this.init();
        this.getProduct();
        this.changeDark();
        const media = window.matchMedia('(prefers-color-scheme: dark)');
        media.addEventListener('change', e => {
            this.dark = e.matches;
            this.changeDark();
        });
        // lottie.loadAnimation({
        //     container: document.getElementById('cat'),
        //     renderer: 'svg',
        //     loop: true,
        //     autoplay: true,
        //     path: '/static/cat-workout-wheel-loading-animation.json'
        // })
    },
    watch: {
        $route() {
            this.getProduct();
        },
        popover(val) {
            if (val) {
                this.$refs.search.focus()
            }
        }
    },
    computed: {
        queryProduct() {
            return this.product_list.filter(p => {
                return !this.query ||
                    (p.name || '').toLowerCase().indexOf(this.query.toLowerCase()) > -1 ||
                    (p.nickname || '').toLowerCase().indexOf(this.query.toLowerCase()) > -1 ||
                    (p.app_id || '').toLowerCase().indexOf(this.query.toLowerCase()) > -1 ||
                    (p.bundle_id || '').toLowerCase().indexOf(this.query.toLowerCase()) > -1
            })
        }
    }
}
</script>

<style>
body {
    margin: 0;
    padding: 0;
    position: relative;
}

.el-header {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px var(--el-border-color-light);
    background-color: var(--el-bg-color);
}

.el-header .title {
    padding: 0 10px;
    line-height: 64px;
    font-size: 20px;
    color: var(--el-text-color-regular);
}

.el-aside, .product-nav:hover {
    background-color: var(--el-border-color-extra-light);
    color: var(--el-text-color-secondary);
}

.el-main {
    background-color: var(--el-border-color-extra-light);
    padding: 0;
}

.el-drawer.rtl {
    overflow: scroll
}

.color-modest {
    color: var(--el-text-color-regular) !important;
}

.color-primary {
    color: var(--el-color-primary) !important;
}

.color-success {
    color: var(--el-color-success) !important;
}

.color-warning {
    color: var(--el-color-warning) !important;
}

.color-error {
    color: var(--el-color-error) !important;
}

.color-info {
    color: var(--el-color-info) !important;
}

.global-cover {
    position: absolute;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 999;
}

.rotating {
    vertical-align: middle;
    animation: 1s linear 1s infinite alternate slidein;
    width: 45px;
}

@keyframes slidein {
    from {
        transform: rotateY(0);
    }
    50% {
        transform: rotateY(180deg);
    }
    to {
        transform: rotateY(360deg);
    }
}

.block {
    cursor: pointer;
    float: right;
}

.block + .block {
    margin-right: 30px;
}

.product {
    font-size: 20px;
    line-height: 64px;
    display: flex;
    /*white-space: nowrap;*/
    /*overflow: hidden;*/
}

.product-nav {
    padding: 7px 5px;
    position: relative;
    cursor: pointer;
    display: flex;
}

.el-scrollbar .el-scrollbar__view {
    height: 100% !important;
}

.is-sortable .sort-caret {
    display: none;
}

.is-sortable .caret-wrapper {
    height: 23px;
}

.is-sortable.ascending .caret-wrapper::after {
    content: '↑';
    font-size: 14px;
}

.is-sortable.descending .caret-wrapper::after {
    content: '↓';
    font-size: 14px;
}

.brand {
    height: 64px;
}

.gmnoprint {
    display: none !important;
}

.el-card {
    display: block;
}

.span {
    animation: span 2s linear infinite;
}

@keyframes span {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(90deg);
    }
    50% {
        transform: rotate(180deg);
    }
    75% {
        transform: rotate(270deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

#cat {
    position: absolute;
    width: 100px;
    height: 100px;
    bottom: 100px;
    right: 100px;
    z-index: 100000;
    cursor: pointer;
}

#chat-gpt {
    width: 60%;
    height: 80%;
    position: fixed;
    top: 34px;
    left: 20%;
    background-color: #FAFAFA;
    display: none;
    z-index: 100000;
}
</style>
