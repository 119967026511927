<template>
    <div style="margin: 30px;width: calc(100% - 60px)">
        <downloadable-table :data="data" :loading="loading" title="历史数据" show-summary :summary-method="summaryMethod">
            <template #header>
                <el-row style="margin-bottom: 5px">
                    <date-range-picker v-model="form"  :disabled="loading"></date-range-picker>
                    <el-select v-model="form.country"  filterable :disabled="loading"
                               style="margin-left: 5px">
                        <el-option label="全部国家" value="GLOBAL"></el-option>
                        <el-option v-for="c in $root.country_list" :key="c.code" :value="c.code"
                                   :label="`${c.en_name}(${c.code})`"></el-option>
                    </el-select>
                    <el-button  type="primary" :loading="loading" @click="init"
                               style="margin-left: 5px">查询
                    </el-button>
                </el-row>
            </template>
            <el-table-column width="120" prop="date" label="日期" sortable></el-table-column>
            <el-table-column width="100" prop="bq_installs" label="首次打开"></el-table-column>
            <el-table-column width="100" prop="ua_installs" label="非自然量"></el-table-column>
            <el-table-column width="100" prop="bq_dau" label="日活"></el-table-column>
            <el-table-column width="100" label="总收入" prop="revenue">
                <template #default="scope">
                    {{
                        scope.row.iaa_revenue + scope.row.iap_proceed ? (scope.row.iaa_revenue + scope.row.iap_proceed).toFixed(2) : null
                    }}
                </template>
            </el-table-column>
            <el-table-column width="100" label="总花费" prop="spend">
                <template #default="scope">
                    {{ scope.row.ua_spend }}
                </template>
            </el-table-column>
            <el-table-column width="100" label="利润" prop="profit">
                <template #default="scope">
                    {{
                        scope.row.iaa_revenue + scope.row.iap_proceed - scope.row.ua_spend ? (scope.row.iaa_revenue + scope.row.iap_proceed - scope.row.ua_spend).toFixed(2) : null
                    }}
                </template>
            </el-table-column>
        </downloadable-table>
    </div>
</template>

<script>
import DownloadableTable from "../../base/DownloadableTable";
import DateRangePicker from "../../base/DateRangePicker";
import axios from 'ts-axios-new';
import {timestampToDate} from "../../libs/utils";

export default {
    name: "SupervisorDashboard",
    components: {DownloadableTable, DateRangePicker},
    data() {
        const now = Date.now();
        return {
            data: [], loading: false, ad_channel: [], cash_channel: [],
            form: {
                start: timestampToDate(now - 32 * 24 * 3600 * 1000) > '2021-09-01' ? timestampToDate(now - 32 * 24 * 3600 * 1000) : '2021-09-01',
                end: timestampToDate(now - this.$root.t * 24 * 3600 * 1000),
                country: 'GLOBAL',
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            this.ad_channel = [];
            this.cash_channel = [];
            axios.get('/api/v1/supervisor/report', {params: this.form}).then(res => {
                this.data = res.data.data.metricList;
                this.ad_channel = res.data.data.ad_channel;
                this.cash_channel = res.data.data.cash_channel;
                this.loading = false;
            })
        },
        summaryMethod({columns, data}) {
            const summary = [];
            columns.forEach((c, i) => {
                if (['bq_dau', 'iaa_revenue'].includes(c.property) || c.property.includes('_revenue') || c.property.includes('_spend') || c.property.includes('iap') || c.property.includes('install')) {
                    summary[i] = 0;
                    let counter = 0;
                    data.forEach(d => {
                        if (d[c.property]) {
                            summary[i] += d[c.property];
                            counter += 1;
                        }
                    });
                    if (c.property === 'bq_dau') {
                        summary[i] = parseInt(summary[i] / counter + '');
                    } else if (c.property.includes('install')) {
                        summary[i] = parseInt(summary[i]);
                    } else {
                        summary[i] = summary[i].toFixed(2);
                    }
                } else if (c.property === 'revenue') {
                    summary[i] = 0;
                    data.forEach(d => {
                        summary[i] += d.iaa_revenue + d.iap_proceed;
                    });
                    summary[i] = summary[i].toFixed(2);
                } else if (c.property === 'spend') {
                    summary[i] = 0;
                    data.forEach(d => {
                        summary[i] += d.ua_spend;
                    });
                    summary[i] = summary[i].toFixed(2);
                } else if (c.property === 'profit') {
                    summary[i] = 0;
                    data.forEach(d => {
                        summary[i] += d.iaa_revenue + d.iap_proceed - d.ua_spend;
                    });
                    summary[i] = summary[i].toFixed(2);
                }
            });
            return summary;
        },
    },
    mounted() {
        this.init();
    },
}
</script>

<style scoped>

</style>